import React from "react"
import { graphql } from "gatsby"

import DocsLayout from "../components/Docs/DocsLayout"

import CategoryList from "../components/Docs/CategoryList"

import SEO from "../components/SEO"
import ReactMarkdown from "react-markdown"

const HelpDocCategory = ({ data, pageContext, path }) => {
  const {
    title,
    slug,
    seoMetaTitle,
    seoMetaDescription,
    body: { body },
    help_doc,
  } = data.doc

  const seoTitle = seoMetaTitle ? seoMetaTitle : title
  const seoDescription = seoMetaDescription ? seoMetaDescription : null

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname={path}
      />
      <DocsLayout path={path}>
        <article className="help-article">
          <header className="help-article__header">
            <h1 className="help-article__header-title">{title}</h1>
          </header>
        </article>
        <section className="help-article__content">
          {body ? <ReactMarkdown source={body} /> : null}
        </section>
        <section className="help-article__categoryList">
          <CategoryList catSlug={slug} list={help_doc} />
        </section>
      </DocsLayout>
    </>
  )
}

export const getHelpDocCat = graphql`
  query getHelpDocCat($id: String!) {
    doc: contentfulHelpDocCategory(contentful_id: { eq: $id }) {
      title
      slug
      seoMetaTitle
      seoMetaDescription
      body {
        body
      }
      help_doc {
        slug
        title
      }
    }
  }
`

export default HelpDocCategory
