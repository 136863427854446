import React from "react"
import Sidebar from "./Sidebar"

import { Container, Row } from 'reactstrap'

const DocsLayout = ({children, path}) => (
    <Container fluid={true} className="Docs_wrapper">
        <Row>
            <Sidebar path={path}/>
            <div className={"col-12 col-md-9 pl-3 py-3 py-md-4 pl-md-5 bd-content"}>{children}</div>
        </Row>
    </Container>
)

export default DocsLayout
