import React from "react"
import { Link } from 'gatsby'

import { ListGroup, ListGroupItem } from 'reactstrap';

const CategoryList = ({list, catSlug}) => {

    if(!list) return null;

    if(list){
        return (
            <ListGroup>
                {list.map((subitem, key) => <ListGroupItem key={key}><Link to={(catSlug ? '/help/' + catSlug + '/' + subitem.slug + "/" : '/help/' + subitem.slug + '/')}>{subitem.title}</Link></ListGroupItem> )}
            </ListGroup>
        )
    }
};

export default CategoryList;