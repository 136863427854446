import React, {useState} from 'react';
import {StaticQuery, graphql} from "gatsby";
import { Link } from 'gatsby'
import { Collapse } from 'reactstrap';

// import Search from './Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import localeContext from '../../utils/localeContext';

const localeChecker = (localeContent, locale) => {
  
  let defaultLocale = (!locale ? 'en-GB' : locale)
  if(localeContent === defaultLocale) return true;
  return false;
}

const SidebarItem = ({ path, catSlug, ...item}) => {
  const { locale } = React.useContext(localeContext)
    
    const sidebarLink = (item.node ? item.node : item)
    // console.log("sidebarLink", sidebarLink)
    // console.log("locale", locale)
    if(localeChecker(sidebarLink.node_locale, locale)){
      if(!sidebarLink.help_doc){

          let url = (catSlug ? '/help/' + catSlug + '/' + sidebarLink.slug + '/' : '/help/' + sidebarLink.slug + '/');
          // category
          return <Link className={path === url ? "active bd-toc-link" : "bd-toc-link"} to={url}>{sidebarLink.title}</Link>
      } else {
          // main item list
          let url = '/help/' + sidebarLink.slug + '/';
          return(
              <div className="bd-toc-item active">
                  <Link className={path === url ? "active bd-toc-link" : "bd-toc-link"} to={url}>{sidebarLink.title}</Link>
                  <ul className="nav bd-sidenav">
                    {sidebarLink.help_doc.map((subitem, key) => (<li key={key}><SidebarItem  path={path} {...subitem} catSlug={sidebarLink.slug}/></li>) )}
                  </ul>
              </div>
          )
      }
    } else return null;
}

const SidebarNav = ({ path }) => (
  <StaticQuery
    query={graphql`
      query {
        CatList: allContentfulHelpDocCategory {
            edges {
              node {
                id: contentful_id
                node_locale
                slug
                title
                help_doc {
                  slug
                  title
                  node_locale
                }
              }
            }
          }
      }
    `}
    render={({CatList}) => {
      const nav = CatList.edges
      return (
        <>
        {nav.map((item, key) => <SidebarItem path={path} key={key} {...item}/> )}
        </>
      );
    }}
  />
);

const Sidebar = ({path}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
  <div className="col-12 col-md-3 bd-sidebar help-sidebar">
      <div className="bd-search d-flex align-items-center">
        <h4 className="hidden-sm" style={{paddingLeft:"10px", paddingTop:"10px"}}>Help Articles</h4>
        <button onClick={toggle} className="btn btn-link bd-search-docs-toggle d-md-none p-0" type="button" data-toggle="collapse" data-target="#bd-docs-nav">
         Help Articles <FontAwesomeIcon icon={(isOpen ? faAngleUp : faAngleDown)} />
        </button>
      </div>
      <Collapse isOpen={isOpen}>
        <nav className="bd-links" id="bd-docs-nav">
          <SidebarNav path={path} />
        </nav>
      </Collapse>
  </div>
  );
};

export default Sidebar;
